import { ConnectedRouter }         from 'connected-react-router'
import { Redirect, Route, Switch } from 'react-router'
import { history }                 from './utils/store'
import AuthenticatedRoute          from './components/AuthenticatedRoute'

import Login      from './components/Login'
import Callback   from './components/Callback'
import DrawerView from './components/DrawerView'
import NotFound   from './components/NotFound'
import Toast      from './components/Toast'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { CssBaseline }   from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import mdfTheme          from './utils/site-theme'
import './App.css'

console.log('%c' + String.fromCodePoint(0x1F64B) + String.fromCodePoint(0x1F3FB) +
  ' Are you interested to help build UAV Surveillance and Tracking Systems? Join us - visit https://garuda.io/careers to learn more!',
  'font-size: 16px; text-shadow: #ddd 1px 1px;')

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ThemeProvider theme={mdfTheme}>
      <CssBaseline />
      <ConnectedRouter history={history}>
        <Switch>
          <Redirect exact from='/' to='/login' />
          <Route exact path='/login' component={Login} />
          <Route exact path='/callback' component={Callback} />
          <AuthenticatedRoute path='/airspace' component={DrawerView} />
          <AuthenticatedRoute path='/zones' component={DrawerView} />
          <AuthenticatedRoute exact path='/dashboard' component={DrawerView} />
          <AuthenticatedRoute path='/playback' component={DrawerView} />
          <AuthenticatedRoute path='/drones' component={DrawerView} />
          <AuthenticatedRoute path='/trackers' component={DrawerView} />
          <AuthenticatedRoute path='/flights' component={DrawerView} />
          <AuthenticatedRoute exact path='/violations' component={DrawerView} />
          <AuthenticatedRoute exact path='/advisories' component={DrawerView} />
          <AuthenticatedRoute exact path='/reports' component={DrawerView} />
          <AuthenticatedRoute exact path='/external' component={DrawerView} />
          <AuthenticatedRoute exact path='/developer' component={DrawerView} />
          <AuthenticatedRoute exact path='/developer/inject-telemetry' component={DrawerView} />
          <Route component={NotFound} />
        </Switch>
      </ConnectedRouter>
      <Toast />
    </ThemeProvider>
    </LocalizationProvider>
  )
}


export default App
