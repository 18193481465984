import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useGetDronesQuery } from '../api/aircraft'

import useCompanies from '../components/useCompanies'
import Media        from '../components/Media'
import DataTable    from '../components/DataTable'
import WaitDialog   from '../components/WaitDialog'
import MainControls from './components/MainControls'
import DroneDetails from './DroneDetails'
import DroneFlights from './DroneFlights'
import NewDrone     from './NewDrone'

import {
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
})

function Drones({ classes, pathname }) {
  const droneId = pathname.split('/drones/')[1]

  const { company_ids } = useCompanies()
  const { data, error, isLoading } = useGetDronesQuery({
    model: true,
    company_ids
  })
  const [ details, setDetails ] = useState(null)
  const [ flights, setFlights ] = useState(null)
  const [ openNew, setOpenNew ] = useState(false)

  useEffect(() => {
    const drones = data?.data?.drones
    if (droneId && drones) {
      const drone = drones.find(t => t.drone_id === droneId)
      if (drone) {
        setDetails(drone)
        document.title = `MyDroneFleets | Drones | ${drone.name}`
      }
    }
  }, [droneId, data])

  if (isLoading)
    return <WaitDialog msg='Loading drones...' />
  else if (error) {
    toast.error('Error loading drones')
    console.log('Error loading drones', error)
  }

  const drones = data?.data?.drones || []

  return (
    <div className={classes.root}>
      <MainControls
        drones={drones}
        onCreate={() => { setOpenNew(true) }}
      />
      <DataTable
        data={drones}
        columns={[
          { label: '', render: d => <Media mediaId={d.avatar || d.drone_model?.avatar || ''} size='thumb' /> },
          { label: 'Name',  key: 'name' },
          { label: 'Model', render: d => d.drone_model?.name },
          { label: 'Category', render: d => d.drone_model?.category },
          { label: 'Status', render: d => d.properties?.simulated ? 'Simulated' : d.serviceable ? 'Servicable' : 'BER' },
          { label: 'Control', render: d => d.drone_model?.control_interface?.toUpperCase() || '' },
          { label: '', render: d => <div className={classes.actions}>
            <Button variant='outlined'
              onClick={e => setDetails(d)}>Details</Button>
            <Button variant='outlined'
              onClick={e => setFlights(d)}>Flights</Button>
          </div> }
        ]}
        sort={{
          order: 'asc',
          by: 'name',
        }}
      />
      { details &&
        <DroneDetails drone={details} onClose={() => setDetails(null)} />
      }
      { flights &&
        <DroneFlights drone={flights} onClose={() => setFlights(null)}/>
      }
      { openNew &&
        <NewDrone
          open={openNew}
          onClose={() => { setOpenNew(false) }}
        />
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname
})

export default connect(mapStateToProps, null)(withStyles(styles)(Drones))
