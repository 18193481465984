import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'
import moment from 'moment'
import {
  useGetTracksByIdQuery,
  useGetTrackerByIdQuery,
} from '../../api/tracker'
import {
  useGetFlightsQuery,
  useGetDroneByIdQuery,
} from '../../api/aircraft'

import CardSlide from '../../components/CardSlide'
import SingleDrone from '../../Dashboard/Data/SingleDrone'
import SingleTracker from '../../Dashboard/Data/SingleTracker'

import LayerControls    from './components/LayerControls'
import PlaybackControls from './components/PlaybackControls'
import PlaybackMap      from './components/PlaybackMap'
import MapControls      from './components/MapControls'
import TrackInfo        from './components/TrackInfo'

import {
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  ChevronLeft,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  left: {
    flexGrow: 1,
  },
  right: {
    width: theme.spacing(36),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  title :{
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'space-between',
  },
  loading: {
    display:    'flex',
    alignItems: 'center',
    gap:        theme.spacing(2),
    padding:    theme.spacing(1),
  },
})

const SPEED = 5 // TODO: to allow users to change the speed

function MapView({ history, trackId, classes }) {

  const [ playing,    setPlaying    ] = useState(false)
  const [ clearTime,  setClearTime  ] = useState(null)

  const [ droneId,    setDroneId    ] = useState('')
  const [ trackerId,  setTrackerId  ] = useState('')
  const [ trackStart, setTrackStart ] = useState(null)
  const [ trackEnd,   setTrackEnd   ] = useState(null)
  const [ tracks,     setTracks     ] = useState([])
  const [ trackIndex, setTrackIndex ] = useState(0)

  const [ showCard,  setShowCard  ] = useState(false)
  const [ map,       setMap       ] = useState(null)
  const [ showOnMap, setShowOnMap ] = useState([
    // 'public-nfz',
    // 'public-test-estate',
    // 'private-nfz',
    // 'flight-geofence',
  ])

  const { data, error, isLoading } = useGetTracksByIdQuery(trackId)
  if (!isLoading) {
    if (error) {
      toast.error('Error fetching tracks')
      console.log('Error fetching Tracks for', trackId, error)
    } else if (data.status !== 'success') {
      toast.warning('Failed fetching tracks')
      console.log('Failed fetching Tracks for', trackId, data)
    }
  }
  useEffect(() => {
    // Clean up invalid data (e.g. telems with no position)
    const tracks = data?.data?.flight
    if (tracks) {
      // for now only care about time / position, future to check against a schema
      const goodTracks = tracks.telemetry?.filter(
        telem => (telem?.t && telem.lat && telem.lng && telem.alt)
      ) || []
      setTrackerId(tracks.tracker_id)
      setDroneId(tracks.drone_id)
      setTrackStart(moment.utc(tracks.start).toDate())
      setTrackEnd(moment.utc(tracks.end).toDate())
      setTracks(goodTracks)
    }
  }, [data])

  const { data: trackerData } = useGetTrackerByIdQuery(trackerId, {
    skip: !trackerId
  })
  const tracker = trackerData?.data?.tracker || null

  const { data: droneData } = useGetDroneByIdQuery(droneId, {
    skip: !droneId
  })
  const drone = droneData?.data?.drone || null

  // TODO: Verify if this new tracker_id search works
  // 1) Tracker turned on (tracks started)
  // 2) Start Flight
  // 3) Stop Flight
  // 4) Tracker turned off (tracks ended, a tracker flight or track is created)
  const { data: flightsData } = useGetFlightsQuery({
    tracker_id: trackerId,
    start_date: trackStart?.getTime(),
    end_date:   trackEnd?.getTime(),
  }, {
    skip: !(trackerId && trackStart && trackEnd)
  })
  const flights = flightsData ? Object.values(flightsData.entities) : null

  function drawTelem(index) {
    // console.log('Drawing index', index, tracks[index])
    setTrackIndex(index)

    if (index + 1 === tracks.length) {
      // console.log('End playback')
      // Leave the drone at the end point (don't change telem)
      setPlaying(false)
      setClearTime(null)
      return
    }

    const duration =
      (moment(tracks[index + 1].t).valueOf()) / SPEED -
      (moment(tracks[index].t).valueOf()) / SPEED
    const myTimeout = setTimeout(() => drawTelem(index + 1), duration)

    setClearTime(myTimeout)
  }

  function handlePlay() {
    if (tracks.length > 0) {
      setPlaying(true)

      if (trackIndex + 1 === tracks.length)
        drawTelem(0)
      else
        drawTelem(trackIndex)
    }
    else {
      toast.error('Flight has no telemetry data')
    }
  }

  function handlePause() {
    setPlaying(false)
    clearTimeout(clearTime)
  }

  const startTime = moment(tracks[0]?.t).valueOf() || 0
  const endTime   = moment(tracks[tracks?.length - 1]?.t).valueOf() || 0
  const totalTime = endTime - startTime
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <div className={classes.title}>
          <IconButton onClick={() => history.push('/playback')}>
            <ChevronLeft />
          </IconButton>
          <Typography variant='h5' style={{ flexGrow: 1 }}>
            Track Playback
          </Typography>
          <MapControls
            map={map}
            showOnMap={showOnMap}
            setShowOnMap={setShowOnMap}
          />
          <LayerControls
            showCard={showCard}
            setShowCard={setShowCard}
          />
        </div>
        { showCard && tracker &&
          <CardSlide subheader='Tracker' showCard={showCard}
            onClose={() => { setShowCard(false) }}>
            <SingleTracker
              tracker={tracker}
              telem={tracks[trackIndex]}
              flights={flights || null}
              onClose={() => { setShowCard(false) }}
            />
          </CardSlide>
        }
        { showCard && drone &&
          <CardSlide subheader='Drone' showCard={showCard}
            onClose={() => { setShowCard(false) }}>
            <SingleDrone
              drone={drone}
              telem={tracks[trackIndex]}
              flights={flights || null}
              onClose={() => { setShowCard(false) }}
            />
          </CardSlide>
        }
        <div id='map'>
          <PlaybackMap
            flights={flights}
            tracks={tracks}
            trackIndex={trackIndex}
            setShowCard={setShowCard}
            setMap={setMap}
            showOnMap={showOnMap}
          />
          <PlaybackControls
            trackStart={trackStart}
            trackEnd={trackEnd}
            playing={playing}
            onPlay={handlePlay}
            onPause={handlePause}
            track={tracks[trackIndex] || null}
            startTime={startTime}
            totalTime={totalTime}
            speed={SPEED}
          />
        </div>
      </div>
      <div className={classes.right}>
        <Typography variant='h6'>
          Track Info
        </Typography>
        { isLoading ?
          <div className={classes.loading}>
            <CircularProgress />
            <Typography>Loading...</Typography>
          </div>
        : <TrackInfo
            flights={flights}
            drone={drone}
            tracker={tracker}
            tracks={tracks}
            trackStart={trackStart}
            trackEnd={trackEnd}
          />
        }
      </div>
    </div>
  )
}

export default withRouter(withStyles(styles)(MapView))
