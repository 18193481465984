import { useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useCreateDroneMutation } from '../../api/aircraft'
import { getDecodedAccessToken } from '../../api/accounts'
import TopXCloseButton     from '../../components/TopXCloseButton'
import GridLabel           from '../components/GridLabel'
import GridField           from '../components/GridField'
import ProviderSelect      from './components/ProviderSelect'
import DroneModelSelect    from './components/DroneModelSelect'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toggleCustomModel: {
    cursor: 'pointer',
  }
})

function NewDrone({ classes, open, onClose }) {
  const [ name,        setName        ] = useState('')
  const [ model,       setModel       ] = useState(null)
  const [ customModel, setCustomModel ] = useState(null)
  const [ provider,    setProvider    ] = useState(null)

  const [ nameError,     setNameError     ] = useState('')
  const [ modelError,    setModelError    ] = useState('')
  const [ providerError, setProviderError ] = useState('')

  const [ createDrone, { isLoading } ] = useCreateDroneMutation()
  const token = getDecodedAccessToken()

  const validateForm = useCallback(() => {
    let hasError = false
    if (name)
      setNameError('')
    else {
      hasError = true
      setNameError('Drone Name is required')
    }
    if (model || customModel)
      setModelError('')
    else {
      hasError = true
      setModelError('Drone Model is required')
    }
    if (provider || customModel)
      setProviderError('')
    else {
      hasError = true
      setProviderError('Drone Provider is required')
    }
    if (hasError)
      return
    const droneDetails = {
      name:        name,
      serviceable: true,
      company_id:  token.company_id,
    }
    if (customModel) {
      droneDetails.custom_drone_model = { name: customModel }
    } else {
      droneDetails.drone_model_id = model.drone_model_id
      droneDetails.provider_id    = provider.provider_id
    }
    createDrone(droneDetails)
    .unwrap()
    .then((result) => {
      if (result.status !== 'success') {
        console.log('Create drone failed', result.status)
        toast.error('Could not create drone')
        return
      }
      toast.info('Successfully created drone')
      setName('')
      setModel(null)
      setProvider(null)
      onClose()
    })
    .catch((err) => {
      console.log('Create drone failed', err)
      toast.error('Failed to create drone')
    })
  }, [name, model, customModel, provider, token.company_id, createDrone, onClose])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>Create a new Drone</DialogTitle>
      <DialogContent>
        <Grid container>
          <GridLabel>Drone Name</GridLabel>
          <GridField>
            <FormControl variant='outlined' fullWidth>
              <TextField
                variant='outlined'
                value={name}
                error={!!nameError}
                helperText={nameError}
                onChange={e => { setName(e.target.value) }}
              />
            </FormControl>
          </GridField>
          <GridLabel>
            { customModel ? 'Custom' : '' } Drone Model
          </GridLabel>
          <GridField>
            {!customModel &&
              <DroneModelSelect
                model={model}
                setModel={setModel}
                setProvider={setProvider}
                error={modelError}
                handleCustomModel={setCustomModel}
              />
            }
            {customModel &&
              <FormControl variant='outlined' fullWidth>
                <TextField
                  variant='outlined'
                  value={customModel}
                  onChange={e => { setCustomModel(null) }}
                />
              </FormControl>
            }
          </GridField>
          { !customModel && <>
            <GridLabel>Drone Provider</GridLabel>
            <GridField>
              <ProviderSelect
                model={model}
                setProvider={setProvider}
                provider={provider}
                error={providerError}
              />
            </GridField>
          </> }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='secondary'
          onClick={validateForm}
          disabled={isLoading}>
          Create Drone
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(NewDrone)
