import { useLazyGetDronesQuery } from '../../../api/aircraft'
import TextBox from '../../../components/Inputs/TextBox'

function DroneSelect({ company_ids, drones, setDrones, error }) {

  const [ getDrones ] = useLazyGetDronesQuery()
  // TODO: This query should be sorted by drone name, to consider rewriting this
  //       using RTK Query's entity and auto sorting.

  return (
    <TextBox
      get={() => getDrones({ company_ids, serviceable: true, model: true })}
      getKey='drones'
      findDefaultValue={true}
      dataId='drone_id'
      dataLabel='name'
      value={drones}
      error={!!error}
      helperText={error}
      onChange={setDrones}
      multiple
      addSelectAll
    />
  )
}

export default DroneSelect
