import {
  intervalToDuration,
  formatDuration,
} from 'date-fns'
import { trackerName } from '../../../utils'
import Company from '../../../components/Text/Company'
import Tracker from '../../../components/Text/Tracker'
import {
  Typography,
} from '@material-ui/core'
import {
  PermDeviceInformation, 
  FlightTakeoff,
  TrendingUp,
} from '@material-ui/icons'
import { makeStyles,withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  item: {
    display: 'flex',
    alignItems: 'flex-start',
    borderTop: '1px solid white',
    marginTop: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.map.grey,
  },
})

function TrackInfo ({ classes, drone, tracker, flights, tracks, trackStart, trackEnd }) {

  let startLocation, endLocation
  if (Array.isArray(tracks) && tracks.length >= 1) {
    const startTelem = tracks[0]
    const endTelem = tracks[tracks.length - 1]
    const startLat = parseFloat(startTelem.lat)
    const startLng = parseFloat(startTelem.lng)
    if (isFinite(startLat) && isFinite(startLng))
      startLocation = startLat + ' ' + startLng
    const endLat = parseFloat(endTelem.lat)
    const endLng = parseFloat(endTelem.lng)
    if (isFinite(endLat) && isFinite(endLng))
      endLocation = endLat + ' ' + endLng
  }

  return (<>
    <div className={classes.list}>
      { drone &&
        <InfoItem
          icon={<PermDeviceInformation />} // switch to drone icon later
          title={drone.name}
          desc={<>
            Model: {drone.drone_model?.name}<br />
          </>}
        />
      }
      { tracker &&
        <InfoItem
          icon={<PermDeviceInformation />}
          title={trackerName(tracker) || 'Unknown Tracker'}
          desc={<>
            Serial: {tracker.serial_number}<br />
            IMEI: {tracker.imei || '-'}<br />
            IMSI: {tracker.imsi || '-'}<br />
            ICCID: {tracker.iccid || '-'}<br />
            Owner: {tracker.owner?.name || '-'}<br />
          </>}
        />
      }
      { trackStart && trackEnd &&
        <InfoItem
          icon={<TrendingUp />}
          title={formatDuration(intervalToDuration({ start: trackStart, end: trackEnd }))}
          desc={<>
            Start: {trackStart.toLocaleString()}<br />
            Near: {startLocation || 'Invalid location'}<br />
            End: {trackEnd.toLocaleString()}<br />
            Near: {endLocation || 'Invalid location'}<br />
          </>}
        />
      }
      { flights?.map(flight => (
          <InfoItem key={flight.flight_id}
            icon={<FlightTakeoff />}
            title={`Flight ${flight.flight_id.slice(-4)}`}
            desc={<>
              Company: <Company id={flight.company_id} name /><br />
              Drone:   {flight.drone?.name}<br />
              Pilots:  {flight.pilots?.map(p => p.name).join(', ')}<br />
              Tracker: {flight.drone?.tracker_ids?.length > 0 ?
                <Tracker id={flight.drone.tracker_ids[0]} name /> :
                'No trackers'
              }<br />
              Permit:  {flight.permits?.map(
                permit => `${permit.authority} ${permit.ref}`
              ) || 'No Permits'}<br />
            </>}
          />
        ))
      }
      <div style={{ position: 'absolute', bottom: '16px' }}>
        <Typography variant='caption'>
          Copyright &copy; {new Date().getFullYear()} Garuda Robotics
        </Typography>
      </div>
    </div>
  </>)
}

function InfoItem({ icon, title, desc }) {
  const classes = makeStyles(styles)()
  return (
    <div className={classes.item}>
      <div className={classes.icon}>
        {icon}
      </div>
      <div>
        <Typography variant='body1'>
          <b>{title}</b>
        </Typography>
        <Typography variant='body2'>
          {desc}
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(TrackInfo)
