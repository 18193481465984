import { getAccessToken } from './accounts'

const MEDIA_URL = new URL(process.env.REACT_APP_MEDIA_URL).href

export function createMediaUrl({ mediaId, size }) {
  if (mediaId && ['original', 'fullscreen', 'preview', 'thumb', 'upnorth'].includes(size)){
    return MEDIA_URL + '/m/' + mediaId + '/' + size + '?token=' + getAccessToken()
  } else {
    return ''
  }
}

//
// https://developer.mozilla.org/en-US/docs/Web/API/File/type
// 
// TODO: Confirm which MIME type Media Service wants to support
//       https://www.iana.org/assignments/media-types/media-types.xhtml#image
//       and add to this list (so that we don't need to round trip)
//
export const imageMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/gif'
]
