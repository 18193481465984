import { useLazyGetProvidersQuery } from '../../../api/aircraft'
import TextBox from '../../../components/Inputs/TextBox'

function ProviderSelect({ model, provider, setProvider, error }) {

  const [ getProviders ] = useLazyGetProvidersQuery()

  return (
    <TextBox
      // TODO: filter by model_id (TextBox design doesn't support such customization)
      get={() => getProviders({ model: model?.model_id })} // this model is an invalid param
      getKey='providers'
      dataId='provider_id'
      dataLabel='name'
      value={provider}
      error={!!error}
      helperText={error}
      onChange={setProvider}
    />
  )
}

export default ProviderSelect
