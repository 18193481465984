import { useGetDroneByIdQuery } from '../../api/aircraft'

function Drone({ id, name, model, category, subcategory, ...rest}) {

  const { data, error, isLoading } = useGetDroneByIdQuery(id, {
    skip: !id
  })

  if (!id || isLoading)
    return ''
  if (error)
    return '(Unavailable Drone)'
  if (data?.status !== 'success'){
    console.log('Invalid Drone ID', id)
    return '(Invalid Drone)'
  }

  const drone = data?.data?.drone || {}
  return <span {...rest}>
    { name        && drone.name }
    { model       && ` (model: ${drone.drone_model?.name     || '-'})` }
    { category    && ` (category: ${drone.category           || '-'})` }
    { subcategory && ` (subcategory: ${drone.subcategory     || '-'})` }
  </span>
}

export default Drone
