import { useGetProviderByIdQuery } from '../../../api/aircraft'
import Media from '../../../components/Media'
import {
  Grid,
} from '@material-ui/core'

function DroneProvider({ provider_id }) {
  const { data } = useGetProviderByIdQuery(provider_id, { skip: !provider_id })
  const provider = data?.data?.provider
  if (!provider_id)
    return null

  return (
    <Grid container>
      { provider.company_logo &&
        <Grid item xs={12} sx={{ width: '100%', height: '100px' }}>
          <Media mediaId={provider.company_logo} size='preview' alt='Provider Company Logo' fill />
        </Grid>
      }
      <Grid item xs={4}>
        Name
      </Grid>
      <Grid item xs={8}>
        {provider.name || '-'}
      </Grid>
      <Grid item xs={4}>
        Brand
      </Grid>
      <Grid item xs={8}>
        {provider.brand || '-'}
      </Grid>
      <Grid item xs={4}>
        Country
      </Grid>
      <Grid item xs={8}>
        {provider.country || '-'}
      </Grid>
      <Grid item xs={4}>
        Website
      </Grid>
      <Grid item xs={8}>
        {provider.company_website || '-'}
      </Grid>
      <Grid item xs={4}>
        Notes
      </Grid>
      <Grid item xs={8}>
        {provider.notes || '-'}
      </Grid>
    </Grid>
  )
}

export default DroneProvider
