import Sockette  from 'sockette'
import { infoAndSay } from '../utils/speech'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createEntityAdapter } from '@reduxjs/toolkit'
import { makeQueryString, makeWssUrl, standardHeaders } from './api-utils'
import { booleanEqual } from '@turf/turf'

const AIRCRAFT_HTTP_API = new URL(process.env.REACT_APP_AIRCRAFT_HTTP_API).href
const AIRCRAFT_WS_API = new URL(process.env.REACT_APP_AIRCRAFT_WS_API).href

let ws     // Sockette
let realWs // WebSocket inside
const wsCache = {}

const flights_adapter = createEntityAdapter({
  selectId: flight => flight.flight_id,
  // unsorted (no sortComparer, probably don't need)
})

async function openWS(url) {
  return new Promise(function (resolve, reject) {
    if (wsCache[url])
      return resolve()
    ws = wsCache[url] = new Sockette(url, {
      timeout: 10000,
      maxAttempts: Infinity,
      onopen: e => {
        console.log('aircraft ws connected')
        realWs = e.target
        resolve(ws)
      },
      onreconnect: e => console.log('aircraft ws reconnecting'),
      onclose: e => console.log('aircraft ws close'),
      onerror: e => console.log('aircraft ws error', e),
    })
  })
}

export const aircraftApi = createApi({
  reducerPath: 'aircraftApi',
  baseQuery: fetchBaseQuery({
    baseUrl: AIRCRAFT_HTTP_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['Providers', 'Drones', 'Docks', 'Flights', 'Drone Models'],
  endpoints: (builder) => ({

    // === PROVIDERS ===
    getProviders: builder.query({
      query: (params) => (`/providers${makeQueryString(params, [
        'name',
        'brand',
        'country',
      ])}`),
      providesTags: ['Providers'],
    }),
    getProviderById: builder.query({
      query: (provider_id) => `/providers/${provider_id}`,
      providesTags: ['Providers'],
    }),

    // === DRONE MODELS ===
    getDroneModels: builder.query({
      query: (params) => (`/drone-models${makeQueryString(params, [
        'name',
        'category',
        'status',
        'provider_ids',
        'limit',
      ])}`),
      providesTags: ['Drone Models'], 
    }),
    createDroneModel: builder.mutation({
      query: (newDroneModel) => ({
        url: '/drone-models',
        method: 'POST',
        body: newDroneModel,
      }),
      invalidatesTags: ['Drone Models'],
    }),

    // === DRONES ===
    getDrones: builder.query({
      query: (params) => (`/drones${makeQueryString(params, [
        'name',
        'serial_number',
        'status',   // omit to return only active drones
        'serviceable',
        'shared',
        'provider_ids',
        'drone_model_ids',
        'limit',
        'model',    // true to include model in response
        // super admin
        'company_ids',
      ])}`),
      providesTags: ['Drones'],
    }),
    getDroneById: builder.query({
      query: (drone_id) => `/drones/${drone_id}?model=true`,
      providesTags: ['Drones'],
    }),
    createDrone: builder.mutation({
      query: (newDrone) => ({
        url: '/drones',
        method: 'POST',
        body: newDrone,
      }),
      invalidatesTags: ['Drones'],
    }),
    updateDrone: builder.mutation({
      query: ({ drone_id, ...newDrone }) => ({
        url: `/drones/${drone_id}`,
        method: 'PATCH',
        body: newDrone,
      }),
      invalidatesTags: ['Drones'],
    }),
    deleteDrone: builder.mutation({
      query: (drone_id) => ({
        url: `/drones/${drone_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Drones'],
    }),
    uploadDroneAvatar: builder.mutation({
      query: ({ drone_id, file }) => {
        const body = new FormData()
        body.append('file', file)
        return {
          url: `/drones/${drone_id}/upload/avatar?model=true`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: ['Drones'],
    }),

    // === DOCK MODELS ===
    getDockModels: builder.query({
      query: (params) => (`/dock-models${makeQueryString(params, [
        'name',
        'status',
        'provider_ids',
        'limit',
      ])}`),
      providesTags: ['Dock Models'],
    }),
    getDockModelById: builder.query({
      query: (dock_model_id) => `/dock-models/${dock_model_id}`,
      providesTags: ['Dock Models'],
    }),

    // === DOCKS ===
    getDocks: builder.query({
      query: (params) => (`/docks${makeQueryString(params, [
        'name',
        'serial_number',
        'status',   // omit to return only active docks
        'dock_model_ids',
        'provider_ids',
        'limit',
        'model',    // true to include model in response
        // super admin
        'company_ids',
      ])}`),
      providesTags: ['Docks'],
    }),
    createDock: builder.mutation({
      query: (newDock) => ({
        url: '/docks',
        method: 'POST',
        body: newDock,
      }),
      invalidatesTags: ['Docks'],
    }),
    updateDock: builder.mutation({
      query: ({ dock_id, ...newDock }) => ({
        url: `/docks/${dock_id}`,
        method: 'PATCH',
        body: newDock,
      }),
      invalidatesTags: ['Docks'],
    }),
    deleteDock: builder.mutation({
      query: (dock_id) => ({
        url: `/docks/${dock_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Docks'],
    }),

    // === FLIGHTS ===
    getFlights: builder.query({
      query: (params) => (`/flights${makeQueryString(params, [
        'status',
        'liveflights_status',
        'pilots',
        'drone',
        'tracker_id',
        'battery_id',
        'paylaod_id',
        'configurations',
        'location',
        'start_date',
        'end_date',
        'serviceable',
        'notes',
        'purpose',
        'geo_point',
        'flight_session_ids',
        // super admin
        'company_ids',
      ])}`),
      async transformResponse(response) {
        // console.log('getFlights transformResponse response', response)
        if (response.status === 'success') {
          return flights_adapter.addMany(
            flights_adapter.getInitialState(),
            response.data.flights
          )
        } else {
          console.log('failed to transform flights', response)
          return flights_adapter.getInitialState()
        }
      },
      providesTags: (result) => {
        // console.log('getFlights providesTags result', result)
        return result ?
          [...result.ids.map(id => ({ type: 'Flights', id })), 'Flights'] :
          ['Flights']
      },
      async onCacheEntryAdded(arg, { getCacheEntry, updateCachedData, cacheEntryRemoved, cacheDataLoaded }) {
        const onMessageCallback = event => {
          let data
          try {
            data = JSON.parse(event.data)
          } catch(e) {
            return console.log(e)
          }
          const new_flight = data?.data?.flights
          if (!new_flight) return
          if (data?.event_type === 'DELETED') {
            updateCachedData(draft => {
              console.log('deleting flight', new_flight)
              flights_adapter.removeOne(draft, new_flight.flight_id)
            })
          } else {
            // CREATED/UPDATED
            const current = flights_adapter.getSelectors().selectById(getCacheEntry().data, new_flight.flight_id)

            const shldUpsert = !arg.status || arg.status.includes(new_flight.status)
            const shldDelete = current && new_flight.status !== current.status
            if (shldUpsert) {
              updateCachedData(draft => {
                if (current?.status !== new_flight.status) {
                  if (new_flight.status === 'flying')
                    infoAndSay(`Notice, ${new_flight.drone.name} has taken off ${
                      new_flight.permits?.length > 0 ?
                      `on permits ${new_flight.permits.map(p => p.ref).join(', ')}` :
                      ''
                    }`)
                  if (new_flight.status === 'preflight') // TODO: review when figuring out RSAF
                    infoAndSay(`Flight for ${new_flight.drone.name} has been scheduled`, true)
                  if (new_flight.status === 'postflight')
                    infoAndSay(`Notice, ${new_flight.drone.name} has landed`)
                } else if (current?.status === 'flying' && !booleanEqual(current?.geofence, new_flight?.geofence)) {
                  infoAndSay(`Notice, Geofence for ${new_flight.drone.name} has been updated`)
                }

                flights_adapter.upsertOne(draft, new_flight)
              })
            } else if (shldDelete) {
              updateCachedData(draft => {
                flights_adapter.removeOne(draft, new_flight.flight_id)
              })
            }
          }
        }

        try {
          await cacheDataLoaded
          if (!(arg?.company_ids))
            return

          const ws_url = makeWssUrl(`${AIRCRAFT_WS_API}/flights`, {
            company_ids: arg?.company_ids
          })

          await openWS(ws_url)
          if (!ws || !realWs)
            return console.log('aircraft ws did not open', ws_url)
          realWs.addEventListener('message', onMessageCallback)
        } catch {}
        await cacheEntryRemoved
        realWs.removeEventListener('message', onMessageCallback)

        console.log('aircraft cache entry removed, refuse to close ws')
        //ws.close()
      },
    }),
    getFlightById: builder.query({
      query: (flight_id) => `/flights/${flight_id}`,
      providesTags: (result, error, arg) => {
        // console.log('getFlightById providesTags result', result)
        return (result?.status === 'success' ?
          [{ type: 'Flights', id: result.data.flight.flight_id }, 'Flights'] :
          ['Flights']
        )
      },
    }),
    createFlight: builder.mutation({
      query: (body) => ({
        url: `/flights`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Flights'],
    }),
    updateFlight: builder.mutation({
      query: ({ flight_id, ...body }) => ({
        url: `/flights/${flight_id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Flights', id: arg.flight_id }],
    }),
    deleteFlight: builder.mutation({
      query: (flight_id) => ({
        url: `/flights/${flight_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Flights', id: arg.flight_id }],
    }),
  }),
})

export const {
  useLazyGetProvidersQuery,
  useGetProviderByIdQuery,
  useLazyGetProviderByIdQuery,

  useLazyGetDroneModelsQuery,
  useCreateDroneModelMutation,

  useGetDronesQuery,
  useLazyGetDronesQuery,
  useGetDroneByIdQuery,
  useLazyGetDroneByIdQuery,
  useCreateDroneMutation,
  useUpdateDroneMutation,
  useDeleteDroneMutation,
  useUploadDroneAvatarMutation,

  useLazyGetDockModelsQuery,
  useLazyGetDockModelByIdQuery,

  useGetDocksQuery,
  useCreateDockMutation,
  useUpdateDockMutation,
  useDeleteDockMutation,

  useGetFlightsQuery,
  useGetFlightByIdQuery,
  useLazyGetFlightByIdQuery,
  useCreateFlightMutation,
  useUpdateFlightMutation,
  useDeleteFlightMutation,
} = aircraftApi
