import { toast } from 'react-toastify'
import {
  useLazyGetDroneModelsQuery,
  useLazyGetProviderByIdQuery,
} from '../../../api/aircraft'
import TextBox from '../../../components/Inputs/TextBox'

function DroneModelSelect({ model, setModel, setProvider, handleCustomModel, error }) {
  const [ getDroneModels  ] = useLazyGetDroneModelsQuery()
  const [ getProviderById ] = useLazyGetProviderByIdQuery()

  function handleChange(model) {
    console.log('setting model', model)
    setModel(model)

    if (model?.provider_ids?.length > 0) {
      getProviderById(model.provider_ids[0])
      .then(result => {
        console.log('result', result)
        setProvider(result?.data?.provider)
      })
      .catch(err => {
        toast.error('Error loading providers')
        console.log('Error loading providers', err)
      })
    }
  }
  return (
    <TextBox
      get={getDroneModels}
      getKey='drone_models'
      dataId='drone_models_id'
      dataLabel='name'
      value={model}
      error={!!error}
      helperText={error}
      onChange={handleChange}
      onCreate={handleCustomModel}
    />
  )
}

export default DroneModelSelect
